import { BasicContentCard as BasicContentCardProps } from '@/generated/contentful'

import { Button } from '../Button'

export function BasicContentCard({
	title,
	description,
	ctaButtonCollection
}: Pick<BasicContentCardProps, 'title' | 'description' | 'ctaButtonCollection'>) {
	return (
		<div className="px-10 py-12 bg-gray-1300">
			<div className="space-y-2.5">
				{title && <h5 className="text-h5">{title}</h5>}

				{description && <p className="mt-4">{description}</p>}
			</div>

			{ctaButtonCollection?.items?.map((ctaButton) => {
				if (!ctaButton?.link || ctaButton?.link?.__typename !== 'ExternalLink') return

				return (
					<Button
						link={{ href: ctaButton.link?.url ?? '' }}
						key={ctaButton?.sys.id}
						className="mt-8 inline-flex"
					>
						{ctaButton?.displayText}
					</Button>
				)
			})}
		</div>
	)
}
