import { ReactNode } from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Node, NodeData } from '@contentful/rich-text-types'
import clsx from 'clsx'
import { ErrorBoundary } from 'react-error-boundary'

import {
	ArticleBodyLinks,
	Asset,
	BlogPostBodyLinks,
	Entry,
	GlossaryBodyLinks
} from '@/generated/contentful'

import { isDevelopment } from '@/utils/isDevelopment'
import { onImageError } from '@/utils/sentry/onImageError'

import { BasicContentCard } from '@/components/BasicContentCard'
import { EmbedIframe } from '@/components/EmbedIframe'
import { EmbedInstagram } from '@/components/EmbedInstagram'
import { EmbedSpotify } from '@/components/EmbedSpotify'
import { Image } from '@/components/Image'
import { Warning } from '@/components/Warning'
import { WistiaVideo } from '@/components/WistiaVideo'

import {
	ResolvedField,
	isAssetExternal,
	isBasicContentCard,
	isRichText,
	isRichTextWrapper
} from '../../../../lib/contentful/utils'
import { RichTextCallout } from '../RichTextCallout'

type Props = {
	className?: string
	textColor?: 'default' | 'white' | 'gray'
	field: ResolvedField
	alignment?: 'left' | 'center' | 'right'
}

export function parseNodeToID(node?: NodeData) {
	return node?.content?.[0]?.value
		? node?.content?.[0]?.value.replace(/[\s!-\/:-@[-`{-~]/gi, '_').toLowerCase()
		: ''
}

function renderOptions(links?: ArticleBodyLinks | BlogPostBodyLinks | GlossaryBodyLinks) {
	const assetMap = new Map<string, Asset>()
	const entriesMap = new Map<string, Entry>()

	for (const asset of links?.assets?.block ?? []) {
		if (!asset?.sys.id) continue
		assetMap.set(asset.sys.id, asset)
	}

	for (const entry of links?.entries?.block ?? []) {
		if (!entry?.sys?.id) continue
		entriesMap.set(entry.sys.id, entry)
	}

	return {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
				const asset = assetMap.get(node.data.target.sys.id)

				if (asset?.contentType?.startsWith('video/')) {
					if (!asset?.url) return null
					return (
						<video width="100%" height="100%" controls>
							<source src={asset.url} type="video/mp4" />
						</video>
					)
				}

				if (asset?.contentType?.startsWith('image/')) {
					if (!asset?.url || !asset?.title || !asset?.width || !asset.height) return null
					return (
						<Image
							src={asset?.url}
							alt={asset.title}
							width={asset.width}
							height={asset.height}
							onError={onImageError}
						/>
					)
				}

				return null
			},

			[BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
				const entry = entriesMap.get(node.data.target.sys.id)

				if (!entry) return null

				if (isAssetExternal(entry)) {
					if (!entry.url) return null

					if (entry.assetType === 'wistia') {
						return <WistiaVideo url={entry.url} popover />
					}

					if (
						entry.assetType === 'youtube' ||
						entry.assetType === 'vimeo' ||
						entry.assetType === 'linkedin' ||
						entry.assetType === 'facebook'
					) {
						return <EmbedIframe url={entry.url} />
					}

					if (entry.assetType === 'podcast') {
						return <EmbedSpotify url={entry.url} />
					}

					if (entry.assetType === 'instagram') {
						return <EmbedInstagram url={entry.url} />
					}

					if (entry.assetType === 'asinLookup') {
						return <EmbedIframe url={entry.url} width="95%" height="400px" />
					}

					if (entry.assetType === 'buyButton') {
						return (
							<EmbedIframe
								url={entry.url}
								width="95%"
								height="800px"
								borderStyle="1px solid black"
							/>
						)
					}
				}

				if (isRichTextWrapper(entry)) {
					return <RichTextCallout entry={entry} />
				}

				if (isBasicContentCard(entry)) {
					return (
						<BasicContentCard
							title={entry.title}
							description={entry.description}
							ctaButtonCollection={entry.ctaButtonCollection}
						/>
					)
				}

				return null
			},

			// Add IDs to headings for Table of Contents
			[BLOCKS.HEADING_1]: (node: NodeData, children: ReactNode) => {
				return <h1 id={`h1_${parseNodeToID(node)}`}>{children}</h1>
			},
			[BLOCKS.HEADING_2]: (node: NodeData, children: ReactNode) => {
				return <h2 id={`h2_${parseNodeToID(node)}`}>{children}</h2>
			},
			[BLOCKS.HEADING_3]: (node: NodeData, children: ReactNode) => {
				return <h3 id={`h3_${parseNodeToID(node)}`}>{children}</h3>
			},
			[BLOCKS.HEADING_4]: (node: NodeData, children: ReactNode) => {
				return <h4 id={`h4_${parseNodeToID(node)}`}>{children}</h4>
			},
			[BLOCKS.HEADING_5]: (node: NodeData, children: ReactNode) => {
				return <h5 id={`h5_${parseNodeToID(node)}`}>{children}</h5>
			},
			[BLOCKS.HEADING_6]: (node: NodeData, children: ReactNode) => {
				return <h6 id={`h6_${parseNodeToID(node)}`}>{children}</h6>
			},
			[INLINES.HYPERLINK]: (node: NodeData, children: ReactNode) => {
				const isInternal = node.data.uri.startsWith('/') || node.data.uri.includes('bigcommerce.')
				return (
					<a
						href={node.data.uri}
						target={`${isInternal ? '_self' : '_blank'}`}
						rel={`${isInternal ? '' : 'noopener noreferrer'}`}
					>
						{children}
					</a>
				)
			}
		}
	}
}

function proseRichTextColor(textColor: Props['textColor']) {
	switch (textColor) {
		case 'white':
			return 'prose-headings:text-white prose-p:text-white'

		case 'gray':
			return 'prose-headings:text-gray-600 prose-p:text-gray-600'

		default:
	}
}

export function ContentfulRichText({ className, textColor, field, alignment = 'left' }: Props) {
	if ('error' in field) {
		if (isDevelopment()) return <Warning className={className}>{field.error}</Warning>
		return null
	}
	if (!isRichText(field.data)) {
		if (isDevelopment()) return <Warning className={className}>Text is not a Document.</Warning>
		return null
	}

	return (
		<ErrorBoundary fallback={<Warning className={className}>Failed to render text.</Warning>}>
			<div
				className={clsx(
					'prose',
					{
						left: 'items-start text-left',
						right: 'items-end text-right',
						center: 'items-center text-center'
					}[alignment],
					className,
					proseRichTextColor(textColor)
				)}
			>
				{documentToReactComponents(field.data.json, renderOptions(field.data.links))}
			</div>
		</ErrorBoundary>
	)
}
