import { ReactNode } from 'react'

import { Link } from '@/components/CustomLink'

type Props = {
	href: string
	children: ReactNode
}
export function WrapperInlineLink({ href, children }: Props) {
	const isInternal = href.startsWith('/') || href.includes('bigcommerce.')
	return (
		<Link
			href={href}
			target={isInternal ? undefined : '_blank'}
			className="font-normal underline text-gray-600 hover:text-bc-blue underline-offset-4 decoration-1 decoration-bc-blue text"
		>
			{children}
		</Link>
	)
}
