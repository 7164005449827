import { ReactNode } from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES, NodeData } from '@contentful/rich-text-types'

import { RichTextWrapper } from '@/generated/contentful'

import { isDevelopment } from '@/utils/isDevelopment'

import { Button } from '@/components/Button'
import { Warning } from '@/components/Warning'

import { WrapperInlineLink } from './WrapperInlineLink'

type Props = {
	entry?: RichTextWrapper
}

const options = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node: NodeData, children: ReactNode) => {
			// Strips out empty text nodes
			const shouldRenderAsInlineLink =
				node.content.filter(
					(content: {
						data: { [key: string]: unknown }
						marks?: Array<unknown>
						nodeType?: string
						value?: string
					}) => {
						if (
							Object.keys(content.data)?.length === 0 &&
							content.marks?.length === 0 &&
							content.nodeType === 'text' &&
							content.value === ''
						) {
							return false
						}

						return true
					}
				).length > 1

			if (shouldRenderAsInlineLink) {
				return (
					<p>
						{node.content.map((item: Document) =>
							documentToReactComponents(item, {
								renderNode: {
									[INLINES.HYPERLINK]: (node: NodeData, children: ReactNode) => {
										return <WrapperInlineLink href={node.data.uri}>{children}</WrapperInlineLink>
									}
								}
							})
						)}
					</p>
				)
			}

			return <p>{children}</p>
		},
		[BLOCKS.LIST_ITEM]: (node: NodeData) => {
			return (
				<li>
					{node.content.map((item: Document) =>
						documentToReactComponents(item, {
							renderNode: {
								[INLINES.HYPERLINK]: (node: NodeData, children: ReactNode) => {
									return <WrapperInlineLink href={node.data.uri}>{children}</WrapperInlineLink>
								}
							}
						})
					)}
				</li>
			)
		},
		[INLINES.HYPERLINK]: (node: NodeData, children: ReactNode) => {
			const isInternal = node.data.uri.startsWith('/') || node.data.uri.includes('bigcommerce.')
			return (
				<span className="block mt-7.5">
					<Button
						variant="filled"
						color="primary"
						link={{ href: node.data.uri, target: isInternal }}
						size="default"
						className="mt-2"
					>
						{children}
					</Button>
				</span>
			)
		}
	}
}

export function RichTextCallout({ entry }: Props) {
	if (!entry) {
		if (isDevelopment()) return <Warning>No Article Callout Found!</Warning>
		return null
	}

	const document = entry.content?.json as Document
	const type = entry.type

	if (type === 'quote') {
		return (
			<div className="case-study-quote [&>p:first-child]:text-xl [&>p:last-child]:mb-0 [&>p:last-child]:uppercase [&>p]:text-bc-black">
				{documentToReactComponents(document)}
			</div>
		)
	}
	if (type === 'gradient') {
		return (
			<div className="flex flex-col justify-center text-center bg-rich-text items-center text-white rounded-lg [&_p]:text-white [&_p]:my-2 [&_span.block]:my-0 p-12 mt-12">
				{documentToReactComponents(document, options)}
			</div>
		)
	}
	return (
		<div className="bg-gray-1300 py-10 pb-6 px-10 mt-12">
			{documentToReactComponents(document, options)}
		</div>
	)
}
